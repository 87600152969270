import api from './api.service'

class DashboardService {
  static async find(id: string) {
    const response = await api.get<object>(`/get-data/${id}`)
    return response.data
  }
}

export default DashboardService
