import React, { useState } from 'react'
import Drawer from 'react-modern-drawer'
import { Icon } from 'semente-js'

const values = {
  Areas: ['Recursos humanos', 'Area 2', 'Area 3', 'Area 4'],
  'Temas materiais': ['Bio', 'Tema 2', 'Tema 3', 'Tema 4']
}

const ImpactFilter = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  return (
    <div>
      <button
        onClick={() => setFilterIsOpen(true)}
        className='flex items-center gap-2 rounded-lg bg-gray-100 px-3 py-[10px]'
      >
        <Icon name='filter' size='18' />
        <span>Filtrar</span>
      </button>

      <Drawer
        open={filterIsOpen}
        size={'400px'}
        onClose={() => setFilterIsOpen(false)}
        direction='right'
      >
        <div className='flex w-[100%] flex-col p-6'>
          <span className='text-3xl font-semibold'>Filtrar</span>

          <input
            placeholder='Procurar'
            className='mb-6 mt-6 w-full rounded-lg border-[1px] border-gray-200 px-4 py-2'
          />

          {(Object.keys(values) as Array<keyof typeof values>).map(key => (
            <div className='flex w-full flex-col py-2' key={key}>
              <span className='text-xl font-semibold'>{key}</span>

              {values[key].map((option, index) => (
                <div
                  className={`flex w-full gap-2  ${values[key].length - 1 !== index && 'border-b-[1px] border-gray-200'} py-3`}
                  key={option}
                >
                  <input type='checkbox' />
                  <span>{option}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  )
}

export default ImpactFilter
