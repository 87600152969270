import { createBrowserRouter } from 'react-router-dom'
import HomePage from '../pages/home'
import { ROUTES } from './path'
import ProtectedRoute from '../components/protected-route'
import DashboardPage from '../pages/dashboard'

export const router = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <ProtectedRoute />,
    children: [
      { index: true, element: <HomePage /> },
      { path: ':id', element: <DashboardPage /> }
    ]
  }
])
