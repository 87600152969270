import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from 'semente-js'

const HomePage = () => {
  const [form, setForm] = useState('')
  const navigate = useNavigate()

  return (
    <main className='flex h-screen w-full flex-col items-center justify-center overflow-auto px-16'>
      <div className='flex w-[300px] flex-col items-center rounded-2xl bg-white px-8 py-12 '>
        <span className='text-2xl font-semibold'>Smart ESG</span>

        <Input
          id='form-filter'
          className='mt-4'
          placeholder='Identificador do formulário'
          onChange={e => setForm(e.target.value)}
          value={form}
        />

        <Button
          label='Buscar'
          className='mt-4 w-full bg-blue-600'
          color='primary'
          onClick={() => navigate(`/${form}`)}
        />
      </div>
    </main>
  )
}

export default HomePage
